import { endOfMonth, startOfDay, startOfMonth, startOfYear, endOfYear, sub, isSameDay } from 'date-fns'

export const PARAMETER_BETWEEN = 'between'
export const PARAMETER_GREATER_THAN = 'gt'
export const PARAMETER_GREATER_THAN_OR_EQUAL = 'gte'
export const PARAMETER_LESS_THAN = 'lt'
export const PARAMETER_LESS_THAN_OR_EQUAL = 'lte'

/* Default ranges for lists and data fetching */

const now = new Date()
const today = startOfDay(now)
const thirtyDaysAgo = startOfDay(sub(now, { days: 30 }))
const twelveMonthsAgo = startOfDay(sub(now, { months: 12 }))

export const startOfThisMonth = startOfMonth(now)
export const endOfThisMonth = endOfMonth(now)

const startOfLastMonth = startOfMonth(sub(now, { months: 1 }))
const endOfLastMonth = endOfMonth(sub(now, { months: 1 }))

const startOfLastYear = startOfYear(sub(now, { years: 1 }))
const endOfLastYear = endOfYear(sub(now, { years: 1 }))

export const startOfThisYear = startOfYear(now)

export const getThisYearRange = () => ({
  start: startOfYear(new Date()),
  end: new Date(),
})

export const isAllRange = (range = {}) => {
  if (range === null) return true
  try {
    if (range.start && range.end) {
      if (isSameDay(new Date(range.start), new Date('2022-01-01')) && isSameDay(new Date(range.end), new Date())) {
        return true
      }
    }
    return false
  } catch (err) {
    console.log(err)
    return false
  }
}

export const listRanges = {
  month: [startOfThisMonth, endOfThisMonth],
  last_30_days: [thirtyDaysAgo, today],
  last_month: [startOfLastMonth, endOfLastMonth],
  year: [startOfThisYear, today],
  last_year: [startOfLastYear, endOfLastYear],
  last_12_months: [twelveMonthsAgo, today],
  //all: [new Date('2022-01-01'), today],
}
