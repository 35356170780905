import { get as _get } from 'lodash-es'

const colors = [
  '#9EB384',
  '#F6635C',
  '#C23373',
  '#279EFF',
  '#94ADD7',
  '#7895CB',
  '#088395',
  '#569DAA',
  '#A4BC92',
  '#159895',
  '#1A5F7A',
  '#9E4784',
  '#28CC9E',
  '#F2921D',
  '#645CBB',
  '#227C70',
  '#4B56D2',
  '#439A97',
]

const lightColors = [
  '#F0DEDE',
  '#F8ECD7',
  '#F8D5C1',
  '#EFF1D9',
  '#DDECEF',
  '#E8DAD7',
  '#DBF9F9',
  '#D9E7F4',
  '#DFE8E9',
  '#EAEBE6',
  '#FFF0D1',
  '#D5E6E7',
  '#D6CEDB',
  '#DFE8E3',
  '#ECF2F0',
  '#E0E8F2',
  '#E3EDE4',
  '#FEEDD9',
  '#FEDADA',
  '#FCFBB8',
  '#FFDDCA',
]

export default function () {
  function hashCode(str) {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }
    return hash
  }

  const pickColor = (str, light = false) => {
    const index = Math.round(Math.abs(hashCode(str) % 360) / 20)
    return _get(light ? lightColors : colors, index, '#4B56D2')
  }

  return { pickColor }
}
