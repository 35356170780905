import { computed, nextTick, ref, useContext } from '@nuxtjs/composition-api'
import { get as _get, has as _has, debounce as _debounce, pick as _pick } from 'lodash-es'
import { stripHTML } from '@/utils/normalizers'
import { useUser } from '@/composables/user'
import { nanoid } from 'nanoid/non-secure'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { camelCase } from 'scule'
import { useLoading } from '@/composables/loading'
import { PRODUCT_TYPE_SUPPLY, PRODUCT_TYPE_WORK_DETAILED } from '@/constants/products'
import { toValue } from '@vueuse/core'
const { useActions } = createNamespacedHelpers('documents')

export function useWorks() {
  const { company, defaultUnitObject } = useUser()

  const defaultWorkItem = (params = {}) => ({
    element: {
      name: '',
      buyPrice: null,
      sellPrice: null,
      reference: null,
      id: null,
      unit: _get(params, 'unit', defaultUnitObject),
      type: PRODUCT_TYPE_SUPPLY,
    },
    key: nanoid(),
    id: null,
    quantity: 1,
    isNew: true,
    lockSellPrice: false,
  })

  return { defaultWorkItem }
}

export function useMargins(product) {
  const { company } = useUser()
  const defaultCoefficient = computed(() => _get(company.value, 'defaultCoefficient', 0))

  const totalCost = computed(() => {
    if (product.buyPrice > 0) {
      return defaultCoefficient.value > 0
        ? product.buyPrice + (product.buyPrice * defaultCoefficient.value) / 100
        : product.buyPrice
    }

    return 0
  })

  const marginRate = computed(() => {
    if (product.buyPrice > 0 && product.sellPrice > 0) {
      return (((product.sellPrice * 100 - totalCost.value * 100) / totalCost.value) * 100) / 100
    }

    return 0
  })

  const margin = computed(() => {
    if (product.buyPrice > 0 && product.sellPrice > 0) {
      let buyPrice =
        defaultCoefficient.value > 0
          ? product.buyPrice + (product.buyPrice * defaultCoefficient.value) / 100
          : product.buyPrice
      return (product.sellPrice * 100 - buyPrice * 100) / 100
    }
    return 0
  })

  function getMarginRateColorClass(rate) {
    if (rate === 0) return 'text-gray-600'
    if (rate < 5) return 'text-red-500'
    if (rate >= 5 && rate <= 30) return 'text-yellow-500'
    return 'text-green-500'
  }

  return {
    margin,
    marginRate,
    totalCost,
    getMarginRateColorClass,
  }
}

export function useProductsSearch({ emit, line, type = [], context = null }, exclude) {
  const { $axios, $productsRepository, app } = useContext()
  const { company, user, can } = useUser()

  const { setLineProduct } = useActions(['setLineProduct'])
  const { setLoading } = useLoading()

  const showSearch = ref(false)
  const searchWidth = ref(0)
  const currentSearch = ref(null)

  const searchError = ref(null)
  const results = ref([])

  let cancelTokenSource = null

  const normalizeResults = (results, source) => {
    for (const [key, result] of Object.entries(results)) {
      results[key] = Object.entries(results[key]).reduce((obj, [k, v]) => {
        if (source === 'batichiffrage') {
          if (['name', 'long_name'].includes(k)) {
            // Strip HTML from batichiffrage names

            v = v.replace(/(<([^>]+)>)/gi, '')
          }
          obj[camelCase(k)] = v
        } else {
          if (k !== 'name') {
            if (k === 'name_raw') {
              k = 'name'
            }

            obj[camelCase(k)] = v
          }
        }

        if (source && source !== 'local') {
          obj.type = source
        }
        return obj
      }, {})
    }
    return results
  }

  const searchProducts = _debounce(async function (e) {
    let excludeIds = toValue(exclude)
    if (cancelTokenSource) {
      cancelTokenSource.cancel()
    }
    cancelTokenSource = $axios.CancelToken.source()

    if (_has(e, 'description') || !['Enter', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowUp'].includes(e.key)) {
      const search = _get(e, 'description', null) || _get(e, 'target.value', null)
      setLoading(true, 'search_products')
      try {
        /* const { data } = search
          ? await $axios.get('search.php', {
              params: {
                limit: 25,
                type: 'product',
                q: search,
                c: company.value.id,
              },
              cancelToken: cancelTokenSource.token,
            })
          : await $axios.get('api/products', {
              params: {
                _limit: 25,
                _search: search,
              },
              cancelToken: cancelTokenSource.token,
              silenceErrors: true,
            }) */

        // user.value.email.includes('@costructor.co') ? 'search_bc.php' : 'search.php',
        let { data } = await $axios.get('search.php', {
          params: {
            limit: 50,
            type: 'product',
            ept: context === 'work' ? 'work' : undefined,
            pt: Array.isArray(type) && type.length ? type : undefined,
            q: search || '',
            c: company.value.id,
          },
          progress: false,
          cancelToken: cancelTokenSource.token,
        })

        let matches = 0
        let firstItem = { name: '' }

        try {
          if (Object.keys(data).length) {
            matches = Object.keys(data).length

            if (Array.isArray(data)) {
              data = data.filter((el) => !(excludeIds || []).includes(_get(el, 'document_id')))
            }

            results.value = normalizeResults(data, 'local')

            firstItem = data[0] || { name: '' }
          } else {
            results.value = []
          }
        } catch (err) {
          console.log(err)
        }

        // Hides the search box if the current search text is === the only result name
        /*if (matches === 0 || (matches === 1 && search !== null && search.trim() === stripHTML(firstItem.name).trim())) {
          showSearch.value = false
        } else {*/
        if (_has(e, 'showSearch')) {
          /*if (matches === 0 && ['', null].includes(search)) {
            showSearch.value = false
          } else {*/
          showSearch.value = true
          // }
        }
        //}

        searchError.value = null
      } catch (err) {
        if (_get(err, 'response.error', null) !== 'unknown') {
          showSearch.value = true
          searchError.value = app.i18n.t('products.message.searchError')
        }
      } finally {
        setLoading(false, 'search_products')
      }
    }
  }, 200)

  const selectResult = async function (result) {
    setLoading(true, `select_product_${result.uuid || result.id}`)
    try {
      if (result.type === 'text') {
        await setLineProduct({
          key: line.key,
          product: _pick(result, ['id', 'name', 'reference']),
        })
      } else {
        let { data } = await $productsRepository.find(result.uuid || result.id, { _expand: ['images'] })

        data.planItem = _get(data, 'planItem.id', null)

        await setLineProduct({
          key: line.key,
          product: data,
        })
      }

      showSearch.value = false
      emit('selectedResult', result.name)
    } catch (err) {
    } finally {
      setLoading(false, `select_product_${result.uuid || result.id}`)
    }
  }

  const toggleShowSearch = (show) => {
    showSearch.value = show
  }

  return {
    searchProducts,
    searchWidth,
    selectResult,
    toggleShowSearch,
    showSearch,
    searchError,
    currentSearch,
    results,
  }
}
