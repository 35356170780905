export default [
  'company_users',
  'company',
  'categories',
  'taxes',
  'companies',
  'documents',
  'messages',
  'quotes',
  'invoices',
  'payments',
  'supplier_invoices',
  'subscription_invoices',
  'journal_entries',
  'contacts',
  'projects',
  'tasks',
  'project_categories',
  'bank_items',
  'bank_accounts',
  'bank_account_transactions',
  'vat_attestations',
  'certificates',
  'order_forms',
  'products',
  'product_categories',
  'project_posts',
  'plan_items',
  'events',
  'users',
  'holdbacks',
  'transactions',
  'timesheets',
  'timesheet_entries',
  'feedbacks',
  'email_templates',
  'files',
  'labels',
  'units',
  'events',
  'notes',
  'work_categories',
  'works',
  'plans',
  'news',
]

// Legacy
export const pluralizeType = {
  company: 'company',
  category: 'categories',
  companies: 'companies',
  company_user: 'company_users',
  document: 'documents',
  quote: 'quotes',
  invoice: 'invoices',
  supplier_invoice: 'supplier_invoices',
  journal_entry: 'journal_entries',
  supplier_other: 'supplier_invoices',
  supplier_credit: 'supplier_credits',
  subscription_invoice: 'subscription_invoices',
  bank_item: 'bank_items',
  bank_account: 'bank_accounts',
  bank_account_transaction: 'bank_account_transactions',
  order_form: 'order_forms',
  credit: 'credits',
  contact: 'contacts',
  project: 'projects',
  task: 'tasks',
  product: 'products',
  product_categories: 'productCategories',
  project_post: 'project_posts',
  holdback: 'holdbacks',
  vat_attestation: 'vat_attestations',
  plan_item: 'plan_items',
  certificate: 'certificates',
  payment: 'payments',
  timesheet: 'timesheets',
  timesheet_entry: 'timesheet_entries',
  file: 'files',
  tax: 'taxes',
}
