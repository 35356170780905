//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, computed } from '@nuxtjs/composition-api'
import useEvents from '@/composables/events'
import { darken, isDark } from 'khroma'

export default defineComponent({
  props: {
    category: Object,
    maxWidth: {
      type: String,
      default: '',
    },
    deletable: Boolean,
    noTooltip: Boolean,
  },
  setup(props) {
    const { pickColor } = useEvents()

    const categoryName = ref()

    const hasEllipsis = computed(() => {
      if (categoryName.value) {
        return categoryName.value.offsetWidth < categoryName.value.scrollWidth
      }
      return false
    })

    const categoryColor = computed(() => {
      let color = pickColor(props.category.id, true)

      if (isDark(color)) {
        //return lighten(color, 10)
      }
      return color
    })

    const categoryDarkColor = computed(() => {
      let color = pickColor(props.category.id, true)
      return darken(color, 10)
    })

    return { props, categoryColor, categoryName, hasEllipsis }
  },
})
