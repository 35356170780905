import { get as _get, has as _has, set as _set } from 'lodash-es'
import { ref, computed, useContext, set, unref, readonly } from '@nuxtjs/composition-api'
import { COMPANY_HAS_SHARE_CAPITAL } from '@/constants/companies'

export const LEGAL_REQUIREMENT_FIELDS = ['taxId', 'shareCapital']

export function useGlobalLogin($bus) {
  const loginModal = ref(null)

  $bus.on('disconnected', () => {
    if (loginModal.value !== null) {
      loginModal.value.openModal()
    }
  })

  return { loginModal }
}

export function useUser(context) {
  const { $auth, $sentry } = useContext()

  const user = computed(() => $auth.user.value)
  const company = computed(() => $auth.company.value)
  const subscription = computed(() => $auth.subscription.value)

  const subscriptionUsers = computed(() => {
    if (_get(subscription.value, 'plan.users', 1) > 1) return true
    if (_get(subscription.value, 'plan.ghosts', 1) === -1) return true
    return _get(subscription.value, 'plan.ghosts', 1) > 0
  })

  const hasUnlimitedSignatures = computed(() => _get(subscription.value, 'plan.unlimited', false))
  const hasUnlimitedOnlinePayments = computed(() => _get(subscription.value, 'plan.unlimited', false))

  const products = computed(() => $auth.products.value)
  const loggedIn = computed(() => $auth.loggedIn.value)

  const taxes = computed(() => [..._get(company.value, 'taxes', [])].sort((a, b) => (a.rate > b.rate ? 1 : -1)))
  const units = _get(company.value, 'units', [])
  const planItems = _get(company.value, 'planItems', [])
  const defaultUnit = _get(company.value, 'defaultUnit.id', null)
  const defaultUnitObject = _get(company.value, 'defaultUnit', null)
  const defaultTax = _get(company.value, 'defaultTax.id', null)

  const updateCompany = (params) => $auth.updateCompany(params)
  const updateUser = (params, persist) => $auth.updateUser(params, persist)
  const getTaxName = (params) => $auth.getTaxName(params)
  const can = (scope, features) => $auth.can(scope, features)
  const cap = (scope, group) => $auth.cap(scope, group)
  const isRoot = () => $auth.isRoot()
  const requiredPlan = (object) => $auth.requiredPlan(object)

  const getUnfulfilledLegalRequirements = function (data) {
    let fields = []

    if (!company.value.taxId) {
      fields.push('taxId')
    }

    if (company.value.taxable && !company.value.vatId) {
      fields.push('vatId')
    }

    try {
      if (company.value.country) {
        if (
          COMPANY_HAS_SHARE_CAPITAL[company.value.country].includes(company.value.type) &&
          !company.value.shareCapital
        ) {
          fields.push('shareCapital')
        }
      }
    } catch (err) {
      $sentry.captureException(err)
    }

    return fields
  }

  return {
    user: readonly(user),
    company: readonly(company),
    subscription: readonly(subscription),
    subscriptionUsers: readonly(subscriptionUsers),
    products: readonly(products),
    loggedIn: readonly(loggedIn),
    taxes,
    defaultTax,
    units,
    planItems,
    defaultUnit,
    defaultUnitObject,
    hasUnlimitedSignatures,
    hasUnlimitedOnlinePayments,
    can,
    cap,
    isRoot,
    requiredPlan,
    updateUser,
    updateCompany,
    getTaxName,
    getUnfulfilledLegalRequirements,
  }
}
