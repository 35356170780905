//
//
//
//
//
//
//

import { defineComponent, useRouter } from '@nuxtjs/composition-api'
export default defineComponent({
  setup() {
    const router = useRouter()

    function reload() {
      window.location.reload()
    }
    return { router, reload }
  },
})
