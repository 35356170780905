//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  DOCUMENT_STATUS_PAID,
  DOCUMENT_STATUS_SCHEDULED,
  DOCUMENT_STATUS_TO_PAY,
  DOCUMENT_STATUS_TO_REVIEW,
  DOCUMENT_SUBTYPE_CREDIT,
  INVOICE,
} from '@/constants/documents'

import { differenceInCalendarDays } from 'date-fns'
import useModal from '@/composables/modal'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import {
  computed,
  defineComponent,
  ref,
  useContext,
  useFetch,
  watch,
  onMounted,
  onBeforeUnmount,
  nextTick,
} from '@nuxtjs/composition-api'
import { useLoading } from '@/composables/loading'
import Teleport from 'vue2-teleport'
import { pluralizeType } from '@/api/resources'

import { useStatus } from '@/composables/document'
import { get as _get } from 'lodash-es'
import { nanoid } from 'nanoid/non-secure'

export default defineComponent({
  components: {
    VuePdfEmbed,
    Teleport,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const modal = useModal()
    const id = ref(nanoid())
    const init = ref(false)

    const trans = 'contacts.'
    const { loaders, isLoading, setLoading } = useLoading()
    const currentTab = ref('quotes')
    const invoiceModal = ref(null)
    const deleteModal = ref(null)
    const previewModal = ref(null)
    const invoice = ref(null)
    const file = ref(null)

    const pluralType = computed(() => pluralizeType[`supplier_${_get(invoice.value, 'type', 'invoice')}`])

    const handleDelete = () => {
      modal.closeModal()
    }

    const { $supplierInvoicesRepository, $bus, $bus2 } = useContext()

    const { fetch, fetchState } = useFetch(async () => {
      if (modal.isModalOpen()) {
        const { data } = await $supplierInvoicesRepository.find(props.id, {
          cdn: 1,
          _expand: ['projects', 'categories', 'payments'],
        })
        invoice.value = data
        file.value = data.file
        init.value = true
      }
    })

    const { setStatus } = useStatus($supplierInvoicesRepository)

    const changeStatus = async function (status) {
      try {
        setLoading(true, `set_status_${status}`)
        await setStatus(invoice.value, status)

        $bus.emit('update.item', {
          type: 'supplier_invoice',
          data: { ...invoice.value, status },
        })

        invoice.value.status = status
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false, `set_status_${status}`)
      }
    }

    const availableStatuses = computed(() => {
      if (invoice.value.type === DOCUMENT_SUBTYPE_CREDIT) {
        switch (invoice.value.status) {
          case DOCUMENT_STATUS_TO_PAY:
            return [DOCUMENT_STATUS_PAID]
          case DOCUMENT_STATUS_PAID:
            return [DOCUMENT_STATUS_TO_PAY]
        }
      }

      switch (invoice.value.status) {
        case DOCUMENT_STATUS_TO_REVIEW:
          return [DOCUMENT_STATUS_TO_PAY, DOCUMENT_STATUS_PAID]
        case DOCUMENT_STATUS_TO_PAY:
          return [DOCUMENT_STATUS_SCHEDULED, DOCUMENT_STATUS_PAID]
        case DOCUMENT_STATUS_SCHEDULED:
          return [DOCUMENT_STATUS_PAID]
      }
    })

    //const listener = ref(null)

    watch(
      () => modal.isModalOpen(),
      (val) => {
        init.value = false
        if (val) {
          /*listener.value = $bus2.on('test', (data) => {
            console.log('event', data)
          })*/

          fetch()
        } else {
          //$bus2.unsub(listener)
        }
      }
    )

    watch(
      () => props.id,
      (val) => val && modal.isModalOpen() && fetch()
    )

    onMounted(() => {
      $bus.on('update.item', ({ type, data }) => {
        if (data && data.id === _get(invoice.value, 'id')) {
          invoice.value = data
        }
      })

      $bus.on('delete.item', async ({ object, type }) => {
        console.log('delete item')
        if (type === 'payment') {
          fetch()
        }
      })
    })

    return {
      ...modal,
      loaders,
      pluralType,
      isLoading,
      props,
      invoice,
      init,
      file,
      trans,
      fetchState,
      currentTab,
      deleteModal,
      previewModal,
      invoiceModal,
      availableStatuses,
      handleDelete,
      changeStatus,
      differenceInCalendarDays,
      DOCUMENT_STATUS_TO_REVIEW,
      DOCUMENT_STATUS_TO_PAY,
      DOCUMENT_SUBTYPE_CREDIT,
      DOCUMENT_STATUS_SCHEDULED,
      DOCUMENT_STATUS_PAID,
      _get,
    }
  },
})
