//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, useContext } from '@nuxtjs/composition-api'
import { useUser } from '@/composables/user'
import { get as _get } from 'lodash-es'
import { useErrorState, useLoading } from '@/composables/loading'
export default defineComponent({
  setup() {
    const { $companiesRepository } = useContext()

    const { company, updateCompany } = useUser()
    const { setLoading, isLoading } = useLoading()
    const { setErrorState } = useErrorState()

    const removeFlag = async (flag) => {
      try {
        let flags = [].concat(_get(company.value, 'flags', []))
        let index = flags.findIndex((el) => el === flag)
        if (index !== -1) {
          flags.splice(index, 1)
        }

        setLoading(true, `remove_flag_${flag}`)
        await $companiesRepository.post(company.value.id, 'remove_flag', { flag })

        updateCompany({
          flags,
        })
      } catch (err) {
        setErrorState(`remove_flag_${flag}`)
      } finally {
        setLoading(false, `remove_flag_${flag}`)
      }
    }

    return { company, removeFlag, isLoading }
  },
})
