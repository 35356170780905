import { render, staticRenderFns } from "./SupplierInvoiceSidePanel.vue?vue&type=template&id=54b01d78&"
import script from "./SupplierInvoiceSidePanel.vue?vue&type=script&lang=js&"
export * from "./SupplierInvoiceSidePanel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loading: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/Loading.vue').default,SidePanelCloseButton: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/SidePanelCloseButton.vue').default,StatusBadge: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/document/StatusBadge.vue').default,ContactBadge: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/contact/ContactBadge.vue').default,ProjectBadge: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/project/ProjectBadge.vue').default,CategoryBadge: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/CategoryBadge.vue').default,Button: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/Button.vue').default,FileModal: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/document/FileModal.vue').default,DocumentDetailsPayments: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/document/details/DocumentDetailsPayments.vue').default,Notes: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/Notes.vue').default,DeleteModal: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/DeleteModal.vue').default,SidePanel: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/SidePanel.vue').default})
