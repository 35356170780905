//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, inject, provide, ref, watch, onMounted } from '@nuxtjs/composition-api'
import { isValid } from 'date-fns'

export default defineComponent({
  props: {
    hide: Function,
    isWeek: Boolean,
  },
  setup(props, { emit }) {
    const isDateRange = inject('isDateRange')
    const dateRange = inject('dateRange')
    const date = inject('date')
    const ranges = inject('ranges')

    const applyRange = (range) => {
      emit('updateDateRange', { start: range[0], end: range[1] })
    }

    const isCurrentRange = (range) =>
      JSON.stringify({ start: range[0], end: range[1] }) === JSON.stringify(dateRange.value)

    const calendarDate = ref(date.value !== null ? date.value : new Date())
    const selectionRangeStart = ref(null)
    const selectionRange = ref({
      start: null,
      end: null,
    })

    onMounted(() => {
      if (!isDateRange.value && isValid(date.value)) calendarDate.value = date.value
    })

    watch(date, (val) => {
      if (val) calendarDate.value = val
    })

    provide('calendarDate', calendarDate)
    provide('selectionRangeStart', selectionRangeStart)
    provide('selectionRange', selectionRange)

    const setCalendarDate = (date) => (calendarDate.value = date)

    return {
      props,
      ranges,
      dateRange,
      isDateRange,
      setCalendarDate,
      applyRange,
      isCurrentRange,
    }
  },
})
