import { render, staticRenderFns } from "./CreateSupplierExpenseModal.vue?vue&type=template&id=152c8446&"
import script from "./CreateSupplierExpenseModal.vue?vue&type=script&lang=js&"
export * from "./CreateSupplierExpenseModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModalCloseButton: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/ModalCloseButton.vue').default,Loading: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/Loading.vue').default,Badge: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/Badge.vue').default,V3DatePicker: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/v3datepicker/V3DatePicker.vue').default,CurrencyInput: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/CurrencyInput.vue').default,BetaBadge: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/BetaBadge.vue').default,Button: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/Button.vue').default,CategoryBadge: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/CategoryBadge.vue').default,SearchDropdown: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/SearchDropdown.vue').default,SupplierInvoiceCategoriesModal: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/SupplierInvoiceCategoriesModal.vue').default,Modal: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/Modal.vue').default})
