//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  defineComponent,
  onMounted,
  useContext,
  useRoute,
  useRouter,
  ref,
  computed,
  provide,
  reactive,
  watch,
  onBeforeUnmount,
} from '@nuxtjs/composition-api'
import { useErrorState, useLoading } from '@/composables/loading'
import { cloneDeep as _cloneDeep, get as _get, has as _has } from 'lodash-es'
import useModal from '@/composables/modal'
import useHighlight from '@/composables/highlight'
import { CONTACT_TYPE_SUPPLIER, CONTACT_TYPE_SUBCONTRACTOR } from '@/constants/contacts'
import {
  SUPPLIER_INVOICE_STATUSES,
  SUPPLIER_CREDIT_STATUSES,
  DOCUMENT_STATUS_TO_PAY,
  DOCUMENT_STATUS_TO_REVIEW,
  DOCUMENT_SUBTYPE_CREDIT,
} from '@/constants/documents'
import {
  PRODUCT_TYPE_MISC,
  PRODUCT_TYPE_SUPPLY,
  PRODUCT_TYPE_MATERIAL,
  PRODUCT_TYPE_SUBCONTRACT,
  PRODUCT_TYPES,
} from '@/constants/products'
import { formatNumber } from '@/utils/documents'
import { handleBackendFormError, hasFormErrors } from '@/utils/error-handler'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import { useUser } from '@/composables/user'
import { nanoid } from 'nanoid/non-secure'
import { useCurrency } from '@/composables/document'
import { pluralizeType } from '@/api/resources'
import { formatTax } from '@/utils/documents'
import { format, parse } from 'date-fns'
export default defineComponent({
  components: {
    VuePdfEmbed,
  },
  props: {
    mode: {
      type: String,
      default: 'create',
    },
    id: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'invoice',
    },
    switchType: Boolean,
    project: Object,
    fromFile: [Object, File],
    fromTransaction: String,
  },
  setup(props, { emit }) {
    const invoiceType = ref(props.type)

    const pluralType = computed(() => pluralizeType[`supplier_${props.type}`])
    const modal = useModal()

    const Currency = useCurrency()
    const { highlight } = useHighlight()

    const { app, $bus, $toast, $axios, $supplierInvoicesRepository } = useContext()
    const { isLoading, setLoading } = useLoading()
    const { hasErrorState, setErrorState } = useErrorState()
    const { company } = useUser()

    const defaultForm = () => ({
      number: null,
      status: invoiceType.value === 'credit' ? DOCUMENT_STATUS_TO_PAY : DOCUMENT_STATUS_TO_REVIEW,
      supplier: null,
      total: null,
      subtotal: null,
      taxable: true,
      expireAt: null,
      issuedAt: null,
      category: PRODUCT_TYPE_MISC,
    })

    const baseCategories = [PRODUCT_TYPE_SUPPLY, PRODUCT_TYPE_MATERIAL, PRODUCT_TYPE_SUBCONTRACT, PRODUCT_TYPE_MISC]

    const defaultErrors = () => ({
      issuedAt: false,
      expireAt: false,
      subtotal: false,
      supplier: false,
      category: false,
      taxes: false,
      tax: {},
    })

    const file = ref(null)
    const previewModal = ref(null)

    const project = ref(null)
    const projects = ref([])
    const futureProjects = ref([])
    const projectsModal = ref(null)

    const categories = ref([])
    const futureCategories = ref([])
    const categoriesModal = ref(null)

    const form = reactive(defaultForm())
    const predictedFields = ref([])
    const predictedSupplier = ref(null)
    const errors = reactive(defaultErrors())
    const hasOpenDropdown = ref(false)

    provide('futureProjects', futureProjects)
    provide('projects', projects)
    provide('futureCategories', futureCategories)
    provide('categories', categories)
    provide('invoice', form)

    const handleProjectsUpdate = ($event) => {
      projects.value = _cloneDeep($event)
    }

    const handleCategoriesUpdate = ($event) => {
      categories.value = _cloneDeep($event)
    }

    const formErrors = reactive(
      Object.keys(defaultForm()).reduce((obj, key) => {
        obj[key] = false
        return obj
      }, {})
    )

    const initForm = (data) => {
      form.status = invoiceType.value === 'credit' ? DOCUMENT_STATUS_TO_PAY : DOCUMENT_STATUS_TO_REVIEW

      for (const key of Object.keys(defaultForm())) {
        if (_has(data, key)) {
          let val = data[key]
          if (['total', 'subtotal'].includes(key) && val !== null) {
            val /= 100
          }
          if (['issuedAt', 'expireAt'].includes(key) && val !== null) {
            if (val instanceof Date) val = val.toISOString()
            val = val.slice(0, 10)
            val = parse(val, 'yyyy-MM-dd', new Date())
          }

          form[key] = val
        }
      }

      // Always init with an empty tax to handle switching taxable
      let taxAmounts = Object.entries(data.taxAmounts).reduce((arr, [k, v]) => {
        arr.push({
          taxId: k,
          amount: Math.round(v.amount * 100) / 10000,
          total: Math.round(v.total * 100) / 10000,
          forced: v.forced || false,
          id: nanoid(),
        })
        return arr
      }, [])

      if (!taxAmounts.length) {
        taxAmounts.push({
          id: nanoid(),
          taxId: _get(getDefaultTaxIfNotUsed(), 'id', null),
          forced: false,
          total: null,
          amount: null,
        })
      }

      taxes.value = taxAmounts

      init.value = true
    }

    const previewUrl = ref(null)
    const uploadProgress = ref(null)
    const fileError = ref(null)
    const init = ref(false)
    const invoiceId = ref(null)

    const createInvoice = async function (file) {
      try {
        setLoading(true, 'supplierInvoice_data')

        const { data: invoiceData } = await $supplierInvoicesRepository.create(
          {
            type: invoiceType.value,
            status: invoiceType.value === 'credit' ? DOCUMENT_STATUS_TO_PAY : DOCUMENT_STATUS_TO_REVIEW,
            file: _get(file, 'id', null),
            projects: projects.value.reduce((arr, el) => {
              arr.push({ projectId: el.project.id, weight: el.weight })
              return arr
            }, []),
            categories: categories.value.reduce((arr, el) => {
              arr.push({ categoryId: el.category.id, weight: el.weight })
              return arr
            }, []),
          },
          { _expand: ['projects', 'categories'] }
        )

        initForm(invoiceData)
        invoiceId.value = invoiceData.id
        predictedFields.value = invoiceData.predictedFields
        predictedSupplier.value = invoiceData.predictedSupplier

        $bus.emit('create.item', { type: `supplier_invoice`, data: invoiceData })
      } catch (err) {
      } finally {
        setLoading(false, 'supplierInvoice_data')
      }
    }

    const handleFile = async function ($event) {
      const uploadedFile = _get($event.target.files, '0', null)

      if (uploadedFile) {
        const fileSize = uploadedFile.size / 1024 / 1024

        if (fileSize > 6) {
          fileError.value = app.i18n.t('gallery.error.fileSize')
          file.value = null
          return
        }

        const formData = new FormData()
        formData.append('file', uploadedFile)
        formData.append('filename', uploadedFile.name)
        formData.append('purpose', 'supplier_invoice')

        try {
          setLoading(true, 'supplierInvoice_file')
          //const imageSizes = await imageSize(previewUrl)

          const { data } = await $axios.post('api/files', formData, {
            params: {
              cdn: 1,
              _expand: 'projects',
            },
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: function (progressEvent) {
              uploadProgress.value = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
            },
          })

          file.value = data.data
          await createInvoice(file.value)
        } catch (err) {
          const error = _get(err, 'response.data.data.error', null)
          const errors = _get(err, 'response.data.errors', [])
          const maxFileSize = _get(err, 'response.data.data.max_size', 6291456)

          if (errors.length) {
            fileError.value = "Une erreur est survenue lors de l'envoi du fichier"
          }

          if (error) {
            fileError.value = app.i18n.t(`gallery.error.${error}`, { size: maxFileSize / 1024 / 1024 })
          }

          file.value = null
        } finally {
          setLoading(false, 'supplierInvoice_file')
        }
      }
    }

    const taxes = ref([])

    const usedTaxes = computed(() =>
      taxes.value.reduce((arr, el) => {
        arr.push(el.taxId)
        return arr
      }, [])
    )

    const getAvailableTaxes = (taxId) => {
      return company.value.taxes
        .filter((el) => !usedTaxes.value.includes(el.id) || el.id === taxId)
        .reduce((arr, tax) => {
          arr.push(tax)
          return arr
        }, [])
    }

    const removeTax = (id) => {
      const index = taxes.value.findIndex((el) => el.id === id)
      if (index !== -1) taxes.value.splice(index, 1)
    }

    const getDefaultTaxIfNotUsed = () =>
      company.value.taxes
        .filter((el) => !usedTaxes.value.includes(el.id))
        .find((el) => el.id === company.value.defaultTax.id)

    const addTax = () => {
      taxes.value.push({
        id: nanoid(),
        taxId: _get(getDefaultTaxIfNotUsed(), 'id', null),
        total: null,
        amount: null,
        forced: false,
      })
    }

    const getTaxById = (id) => company.value.taxes.find((el) => el.id === id)

    const updateTaxId = (id, val) => {
      let taxesArray = _cloneDeep(taxes.value)

      const index = taxesArray.findIndex((el) => el.id === id)
      let taxRate = company.value.taxes.find((el) => el.id === val)
      if (index !== -1 && taxesArray[index] && taxRate) {
        taxesArray[index].taxId = val
        taxRate = taxRate.rate / 10000
        if (!taxesArray[index].forced) {
          taxesArray[index].amount = Currency.round(taxesArray[index].total * taxRate * 100) / 100
        }
      }

      taxes.value = taxesArray
    }

    const updateTaxTotal = (id, val) => {
      let taxesArray = _cloneDeep(taxes.value)
      const index = taxesArray.findIndex((el) => el.id === id)
      if (index !== -1 && taxesArray[index]) {
        taxesArray[index].total = val
        let taxRate = company.value.taxes.find((el) => el.id === taxesArray[index].taxId)
        if (!taxesArray[index].forced) {
          if (taxRate) {
            taxRate = taxRate.rate / 10000
            taxesArray[index].amount = Currency.round(taxesArray[index].total * taxRate * 100) / 100
          }
        }
      }

      taxes.value = taxesArray
    }

    const updateTaxAmount = (id, val) => {
      let taxesArray = _cloneDeep(taxes.value)
      const index = taxesArray.findIndex((el) => el.id === id)
      if (index !== -1 && taxesArray[index]) {
        taxesArray[index].amount = val
      }

      taxes.value = taxesArray
    }

    const toggleLineTaxForced = (id) => {
      let taxesArray = _cloneDeep(taxes.value)

      const index = taxesArray.findIndex((el) => el.id === id)

      if (index !== -1) {
        let forced = !taxesArray[index].forced
        taxesArray[index].forced = forced

        taxes.value = taxesArray

        taxesArray = _cloneDeep(taxes.value)

        if (forced === false) {
          updateTaxTotal(id, taxesArray[index].total)
        }
      }
    }

    const calculateTotals = (val) => {
      if (form.taxable) {
        let subtotal = 0
        let total = 0

        for (const tax of val) {
          if (tax.total) {
            subtotal += tax.total
            total += tax.total + tax.amount
          }
        }

        form.subtotal = subtotal
        form.total = total
      }
    }

    watch(taxes, (val) => {
      calculateTotals(val)
    })

    function submitFormRaw() {
      Object.assign(errors, defaultErrors())
      Object.assign(
        formErrors,
        Object.keys(defaultForm()).reduce((obj, key) => {
          obj[key] = false
          return obj
        }, {})
      )

      if (form.taxable) {
        let zeroTaxLines = taxes.value.filter((el) => el.total === null)
        if (zeroTaxLines.length) {
          for (const zeroTax of zeroTaxLines) {
            errors.tax[zeroTax.id] = 'Veuillez indiquer un montant'
          }
        }

        let emptyTaxLines = taxes.value.filter((el) => el.taxId === null)
        if (emptyTaxLines.length) {
          for (const emptyTax of emptyTaxLines) {
            errors.tax[emptyTax.id] = 'Veuillez sélectionner un taux de TVA'
          }
        }
      }

      if (form.issuedAt === null) errors.issuedAt = "Veuillez indiquer la date d'émission"
      if (form.issuedAt === null) errors.expireAt = "Veuillez indiquer la date d'échéance"
      if (form.supplier === null) errors.supplier = 'Veuillez indiquer le fournisseur'

      if (!form.taxable && form.subtotal === null) {
        errors.subtotal = 'Veuillez indiquer un montant'
      }
    }

    const resetForm = () => {
      Object.assign(form, defaultForm())
      projects.value = []
      futureProjects.value = []
      categories.value = []
      futureCategories.value = []
      taxes.value = []
    }
    const submit = async () => {
      submitFormRaw()

      if (!hasFormErrors(errors)) {
        setLoading(true, 'create_supplierInvoice')
        try {
          let total = _get(form, 'total', null)
          if (total) total = Math.round(total * 10000) / 100

          let subtotal = _get(form, 'subtotal', null)
          if (subtotal) subtotal = Math.round(subtotal * 10000) / 100

          let taxAmounts = form.taxable
            ? taxes.value.reduce((obj, tax) => {
                let taxObject = company.value.taxes.find((el) => el.id === tax.taxId)

                if (taxObject) {
                  obj[tax.taxId] = {
                    amount: Math.round(tax.amount * 10000) / 100,
                    rate: taxObject.rate,
                    forced: tax.forced,
                    total: Math.round(tax.total * 10000) / 100,
                  }
                }

                return obj
              }, {})
            : []

          let issuedAt = form.issuedAt
          let expireAt = form.expireAt

          if (issuedAt) {
            issuedAt = format(new Date(issuedAt), 'yyyy-MM-dd')
          }

          if (expireAt) {
            expireAt = format(new Date(expireAt), 'yyyy-MM-dd')
          }

          let payload = {
            ...form,
            issuedAt,
            expireAt,
            taxAmounts,
            file: undefined,
            supplier: _get(form, 'supplier.id', null),
            projects: projects.value.reduce((arr, el) => {
              arr.push({ projectId: el.project.id, weight: el.weight })
              return arr
            }, []),
            category: form.category,
            categories: categories.value.reduce((arr, el) => {
              arr.push({ categoryId: el.category.id, weight: el.weight })
              return arr
            }, []),
            //category: invoiceType.value === DOCUMENT_SUBTYPE_CREDIT ? null : form.category,
            type: invoiceType.value,
            subtotal,
            total,
          }

          const { data } = await $supplierInvoicesRepository.update(invoiceId.value, payload, {
            cdn: 1,
            _expand: ['projects', 'categories', 'payments'],
            from_transaction: props.fromTransaction || undefined,
          })

          $toast.show(props.mode === 'create' ? 'La facture a été enregistrée' : 'La facture a été mise à jour')
          $bus.emit(`update.item`, { type: 'supplier_invoice', data })

          emit('updated', data)
          modal.closeModal()
        } catch (err) {
          console.log(err)
          Object.assign(formErrors, handleBackendFormError(app, err))
          setErrorState('create_supplierInvoice')
        } finally {
          setLoading(false, 'create_supplierInvoice')
        }
      }
    }

    const searchContacts = ref(null)

    onMounted(() => {
      $bus.on('create.item', ({ type, data }) => {
        if (type === 'contact') {
          if ([CONTACT_TYPE_SUBCONTRACTOR, CONTACT_TYPE_SUPPLIER].includes(data.type)) {
            form.supplier = data
            if (_get(predictedSupplier.value, 'companyName', null) === _get(data, 'companyName', undefined)) {
              predictedSupplier.value = null
            }
            if (searchContacts.value) {
              searchContacts.value.reload()
            }
          }
        }
      })
    })

    onBeforeUnmount(() => {
      $bus.off('create.item')
    })

    watch(
      () => modal.isModalOpen(),
      async (val) => {
        if (val) {
          invoiceType.value = props.type
          resetForm()
          Object.assign(errors, defaultErrors())
          file.value = null
          if (props.project) {
            projects.value = [{ project: props.project, weight: 100 }]
          }
          if (props.category) {
            categories.value = [{ category: props.category, weight: 100 }]
          }
          if (props.id) {
            invoiceId.value = props.id
            try {
              const { data } = await $supplierInvoicesRepository.find(props.id, {
                _expand: ['projects', 'categories', 'file'],
                cdn: 1,
              })
              if (_has(data, 'file')) file.value = data.file
              if (_has(data, 'projects')) projects.value = data.projects.map((el) => ({ ...el, step: 1 }))
              if (_has(data, 'categories')) categories.value = data.categories.map((el) => ({ ...el, step: 1 }))
              initForm(data)
            } catch (err) {
              console.log(err)
            }
          } else {
            invoiceId.value = null
          }

          if (props.fromFile) {
            file.value = props.fromFile
            await createInvoice(props.fromFile)
          }
        }
      }
    )

    watch(
      () => form.taxable,
      (val) => {
        if (val === false) {
          if (form.subtotal === 0) form.subtotal = null
          form.total = form.subtotal
        } else {
          calculateTotals(taxes.value)
          errors.subtotal = false
        }
      }
    )

    watch(
      () => form.subtotal,
      (val) => {
        if (!form.taxable) {
          form.total = val
        }
      }
    )

    const handleSelectProject = (project) => {
      if (project !== null) {
        if (projects.value.length === 0) {
          projects.value.push({ project, weight: 100 })
        } else {
          futureProjects.value = [].concat(projects.value, [{ project, weight: 0 }])
          projectsModal.value.openModal()
        }
      }
    }

    const handleSelectCategory = (category) => {
      if (category !== null) {
        if (categories.value.length === 0) {
          categories.value.push({ category, weight: 100 })
        } else {
          futureCategories.value = [].concat(categories.value, [{ category, weight: 0 }])
          categoriesModal.value.openModal()
        }
      }
    }

    const openRepartition = () => {
      futureProjects.value = projects.value
      projectsModal.value.openModal()
    }

    const openCategoryRepartition = () => {
      futureCategories.value = categories.value
      categoriesModal.value.openModal()
    }

    const removeProject = (project) => {
      let index = -1

      switch (projects.value.length) {
        case 1:
          projects.value = []
          break
        case 2:
          index = projects.value.findIndex((el) => project.id === el.project.id)
          if (index !== -1) {
            projects.value.splice(index, 1)
          }
          projects.value.map((el) => (el.weight = 100))
          break
        default:
          futureProjects.value = [].concat(projects.value)
          index = futureProjects.value.findIndex((el) => project.id === el.project.id)
          if (index !== -1) {
            futureProjects.value.splice(index, 1)
          }

          projectsModal.value.openModal()
      }
    }

    const removeCategory = (category) => {
      let index = -1

      switch (categories.value.length) {
        case 1:
          categories.value = []
          break
        case 2:
          index = categories.value.findIndex((el) => category.id === el.category.id)
          if (index !== -1) {
            categories.value.splice(index, 1)
          }
          categories.value.map((el) => (el.weight = 100))
          break
        default:
          futureCategories.value = [].concat(categories.value)
          index = futureCategories.value.findIndex((el) => category.id === el.category.id)
          if (index !== -1) {
            futureCategories.value.splice(index, 1)
          }

          categoriesModal.value.openModal()
      }
    }

    return {
      ...modal,
      invoiceType,
      pluralType,
      init,
      file,
      previewModal,
      form,
      formErrors,
      projects,
      futureProjects,
      projectsModal,
      handleSelectProject,
      handleProjectsUpdate,
      removeProject,
      errors,
      fileError,
      predictedFields,
      predictedSupplier,
      previewUrl,
      uploadProgress,
      searchContacts,
      hasOpenDropdown,
      baseCategories,
      categories,
      futureCategories,
      categoriesModal,
      handleSelectCategory,
      handleCategoriesUpdate,
      removeCategory,
      company,
      taxes,
      getAvailableTaxes,
      updateTaxTotal,
      updateTaxAmount,
      updateTaxId,
      addTax,
      removeTax,
      handleFile,
      submit,
      highlight,
      formatNumber,
      formatTax,
      getTaxById,
      isLoading,
      hasErrorState,
      openRepartition,
      openCategoryRepartition,
      toggleLineTaxForced,
      PRODUCT_TYPES,
      CONTACT_TYPE_SUPPLIER,
      CONTACT_TYPE_SUBCONTRACTOR,
      SUPPLIER_INVOICE_STATUSES,
      SUPPLIER_CREDIT_STATUSES,
      DOCUMENT_SUBTYPE_CREDIT,
      _get,
    }
  },
})
